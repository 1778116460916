/* src/views/GolfLessons.css */

.golf-page {
  padding-top: 0px; 
}

.golf-lessons-container h2{
    font-family: 'GTSD Bold';
    font-size: 70px;
    color: #2E2E2E;
    padding-top: 2%;
}
.coaches-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.coach-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3E8DD;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.coach-card:hover {
  transform: scale(1.05);
}

.coach-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.coach-card h5 {
  margin-top: 10%;
  margin-bottom: 0.5em;
  font-family: 'GTSD Bold';
  font-size: 24px;
}

.coach-card p {
  font-family: 'AP Pro Medium';
  color: #2E2E2E;
  font-size: large;
  text-align: center;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #2D3C34;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  font-family: 'AP Pro Medium';
}

.button:hover {
  background-color: #2D3C34;
}

.full-width-button {
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .coaches-container {
    display: flex;
    flex-direction: column;
  }

  .coach-card {
    text-align: left; 
  }
}

.button {
  padding: 10px 20px;
  border: 2px solid transparent;
  background-color: #2D3C34;
  color: #FFFBB3;
  font-size: 16px;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  font-family: 'AP Pro Medium';
}

.button:hover {
  border-color: #FFFBB3;
}

.button:active {
  background-color: #fff;
}

/* Add these styles to your GolfLesson.css file */

.booking-button {
  display: inline-block;
  padding: 14px 32px;
  background-color: #2D3C34;
  color: #FFFBB3;
  font-size: 18px;
  font-weight: bold;
  font-family: 'AP Pro Medium', sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 20px auto;
  min-width: 200px;
}

.booking-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 251, 179, 0.1);
  transition: width 0.3s ease;
  z-index: -1;
}

.booking-button:hover {
  transform: translateY(-3px);
  border-color: #FFFBB3;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.booking-button:hover::before {
  width: 100%;
}

.booking-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.centered-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

/* Alternative gradient button style - add to GolfLesson.css */

.booking-button-gradient {
  display: inline-block;
  padding: 16px 34px;
  background: linear-gradient(135deg, #2D3C34 0%, #4A6B57 100%);
  color: #FFFBB3;
  font-size: 18px;
  font-weight: bold;
  font-family: 'AP Pro Medium', sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(45, 60, 52, 0.3);
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 30px auto;
  min-width: 220px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.booking-button-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #4A6B57 0%, #2D3C34 100%);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.booking-button-gradient:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(45, 60, 52, 0.4);
  color: #FFFDD4;
}

.booking-button-gradient:hover::before {
  opacity: 1;
}

.booking-button-gradient:active {
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(45, 60, 52, 0.3);
}

/* Pulsing effect */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 60, 52, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(45, 60, 52, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(45, 60, 52, 0);
  }
}

.booking-button-pulse {
  animation: pulse 2s infinite;
}