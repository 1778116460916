.page-container {
    min-height: 100vh;
    background-color: #2D3C34;
    display: flex;
    flex-direction: column;
}

.main-content {
    flex: 1;
    background-color: #fff;
    width: 95%;
    margin: 100px auto 60px;
    padding: 0;
    overflow: hidden;
}

.main-content h1 {
    font-family: 'GTSD REGULAR', serif;
    font-size: 48px;
    color: #2e2e2e;
    text-align: center;
    margin: 40px 0;

}

.regions-wrapper {
    width: 100%;
    padding: 0;
}

.region-section {
    margin-bottom: 2px;
    width: 100%;
}

.region-button {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    background-color: #F3E8DD;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: 'AP Pro Medium', sans-serif;
    font-size: 14px;
    color: #2e2e2e;
    transition: background-color 0.3s ease;
}

.region-button:hover {
    background-color: #EDE2D7;
}

.region-button .chevron-icon {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
}

.region-button.expanded .chevron-icon {
    transform: rotate(180deg);
}

.clubs-list {
    padding: 30px 20px;
    background-color: #fff;
    width: 100%;
}

.club-item {
    margin-bottom: 40px;
    width: 100%;
    word-wrap: break-word;
}

.club-item:last-child {
    margin-bottom: 0;
}

.club-item h3 {
    font-family: 'AP Pro Medium', sans-serif;
    font-size: 16px;
    color: #2e2e2e;
    margin-bottom: 10px;
}

.club-item .description {
    font-family: 'AP Pro Regular', sans-serif;
    font-size: 14px;
    color: #2e2e2e;
    margin-bottom: 15px;
    line-height: 1.5;
}

.club-item .details {
    font-family: 'AP Pro Regular', sans-serif;
    font-size: 14px;
    color: #2e2e2e;
}

.club-item .details p {
    margin: 5px 0;
    word-break: break-word;
}



@media (max-width: 1200px) {
    .main-content {
        width: 98%;
    }
}

@media (max-width: 768px) {
    .main-content {
        margin-top: 80px;
        width: 100%;
        border-radius: 0;
    }

    .main-content h1 {
        font-size: 36px;
        margin: 30px 0;
    }
}

@media (max-width: 480px) {
    .main-content h1 {
        font-size: 28px;
    }

    .club-item h3 {
        font-size: 15px;
    }

    .club-item .description,
    .club-item .details,
    .contact-section {
        font-size: 13px;
    }

    .region-button {
        padding: 12px 15px;
    }
}